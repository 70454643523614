import { useCallback, useState } from "react";
import {
  Container,
  Navbar,
  Nav,
  Button,
  Row,
  Col,
  Card,
  Accordion,
} from "react-bootstrap";
import Logo from "../assets/logo.png";
import Icon from "../assets/logo_alpha.png";
import RenderLogo from "../assets/logo_alpha.png";
import GitHubPullRequest from "../components/GitHubPullRequest";
import CodyCanBe from "../components/CodyCanBe";
import BeyonHumanCoding from "../components/BeyondHumanCoding";
import Steps from "../components/Steps";
import BulletPoint from "../components/BulletPoint";
import Pricing from "../components/Pricing";
import LogoFlat from "../assets/logo_alpha.png";
import Divider from "../assets/divider.png";
import Roadmap from "../components/Roadmap";
import Tokenomics from "../components/Tokenomics";

function Home() {
  const isMobile = window.innerWidth < 700;

  const [expanded, setExpanded] = useState(false);
  const handleNavbarToggle = () => {
    setExpanded(!expanded);
  };
  const scrollToTarget = (targetId) => {
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <Container fluid className="p-0">
      <Navbar
        variant="dark"
        expand="md"
        fixed="top"
        expanded={expanded}
        className={expanded ? "blurred-background" : ""}
        style={{
          backgroundColor: "transparent",
          border: "1px solid white",
          margin: "20px 40px",
        }}
      >
        <Container fluid className="p-0">
          <Navbar.Brand
            style={{
              width: "200px",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
            className="ms-3"
          >
            <img
              style={{ width: "15%", marginRight: "15px" }}
              src={Icon}
              alt="Logo"
            />
            <p style={{ marginBottom: "0px", color: "#d9cdf5" }}>CODYN</p>
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            className="me-3"
            onClick={handleNavbarToggle}
          />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link
                href="#"
                onClick={() => window.open("https://docs.codyn.ai", "_blank")}
              >
                Docs
              </Nav.Link>
              <Nav.Link href="#" onClick={() => scrollToTarget("pricing")}>
                Pricing
              </Nav.Link>
              <Nav.Link
                href="#"
                onClick={() => window.open("https://app.codyn.ai", "_blank")}
              >
                App
              </Nav.Link>
              <Nav.Link
                href="#"
                onClick={() =>
                  window.open("https://x.com/codyndev_ai", "_blank")
                }
              >
                Twitter
              </Nav.Link>
              <Nav.Link href="#" onClick={() => window.open("https://t.me/codynai", "_blank")}>
                Telegram
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Container
        style={{
          marginTop: isMobile ? 100 : 180,
          marginBottom: isMobile ? 80 : 180,
        }}
      >
        <Row className="align-items-center">
          <Col xs={12} md={6} className="text-center order-1 order-md-2">
            <img
              src={RenderLogo}
              style={{
                width: isMobile ? "60%" : "30%",
                marginTop: isMobile ? 20 : 0,
                marginBottom: isMobile ? 80 : 0,
              }}
            />
          </Col>

          <Col xs={12} md={6} className="order-2 order-md-1">
            <h1>Your Personal</h1>
            <h1>AI Software Engineer.</h1>
            <p>
              From code logic to documentation, Cody streamlines your
              development.
            </p>
            <p>Try it now to get it done in just a GitHub Pull Request</p>
            <Button
              className="cta-button cta-button-light"
              size="lg"
              href="/new"
            >
              <b>Get Cody</b>
            </Button>
            <Button
              className="ms-3 cta-button cta-button-dark"
              size="lg"
              href="https://t.me/codynai"
              target="_blank"
            >
              Join Telegram
            </Button>
          </Col>

          <Col xs={12} className="order-3">
            <img
              style={{ width: "100%", height: "2px", marginTop: 130 }}
              src={Divider}
            />
          </Col>
        </Row>
      </Container>

      <GitHubPullRequest />

      <Container
        style={{
          marginTop: isMobile ? 80 : 180,
          marginBottom: isMobile ? 80 : 0,
        }}
      >
        <Row className="align-items-center">
          <Col xs={12} md={8}>
            <h1>Code less, delegate more</h1>
          </Col>
          <Col xs={12} md={5}>
            <p>
              Ask Codyn to implement a feature, document your code, add unit
              tests. Embrace the convenience of Codyn and elevate your coding
              experience to new heights.
            </p>
          </Col>
        </Row>
      </Container>

      <img
        style={{
          width: "100%",
          height: "2px",
          margin: isMobile ? "0px 0px 40px 0px" : "100px 0px 40px 0px",
        }}
        src={Divider}
      />

      <CodyCanBe />

      <BeyonHumanCoding />

      <Steps />

      <BulletPoint
        title={"Automated Testing"}
        description={
          "Codyn can automate your testing process, ensuring your codebase remains robust and error-free."
        }
        color={"#825bd7"}
      />
      <BulletPoint
        title={"Code Generation"}
        description={
          "Codyn can generate code snippets, boilerplate code, and entire modules with ease, saving precious development time."
        }
        color={"#8562d7"}
      />
      <BulletPoint
        title={"Code Linting"}
        description={"Codyn can keep your codebase clean and well organized."}
        color={"#9576dd"}
      />
      <BulletPoint
        title={"CI/CD Setup"}
        description={
          "Codyn can configure and optimize your CI/CD pipelines for seamless automation from code commit to deployment."
        }
        color={"#9576dd"}
      />
      <BulletPoint
        title={"Security Audits"}
        description={
          "Improve your code's security posture with Codyn's ability to conduct basic security audits and vulnerability assessments."
        }
        color={"#aa92e3"}
      />
      <BulletPoint
        title={"Documentation Generation"}
        description={
          "Keep your project documentation up-to-date effortlessly with Codyn's automatic documentation generation capabilities."
        }
        color={"#aa92e3"}
      />
      <BulletPoint
        title={"Code Refactoring"}
        description={
          "Improve your code structure and readability with Codyn's refactoring services, making your codebase more maintainable and scalable."
        }
        color={"#c6b9e7"}
      />
      <BulletPoint
        title={"Dependency Management"}
        description={
          "Codyn can update and manage your project's dependencies, ensuring you're always using the most secure and up-to-date libraries."
        }
        color={"#c6b9e7"}
      />

      <Pricing />

      <Roadmap />

      <Tokenomics />

      <Container>
        <Row className="px-3 justify-content-center">
          <Col
            xs={12}
            md={4}
            className="mb-5 d-flex align-items-center justify-content-center"
            style={{ paddingBottom: isMobile ? "60px" : "auto" }}
          >
            <img
              src={LogoFlat}
              alt="Logo"
              style={{ width: "28%", marginRight: "30px" }}
            />
            <p
              style={{ marginBottom: "0", color: "#d9cdf5", fontSize: "50px" }}
            >
              <b>CODYN</b>
            </p>
          </Col>

          <Col xs={12} md={2} className="mb-5 text-left">
            <h3 style={{ color: "#d3c7ef" }} className="footer-title">
              $CODYN
            </h3>
            <p
              className="footer-link"
              onClick={() =>
                window.open(
                  "https://www.dextools.io/app/en/ether/pair-explorer/0xd2ab7d66a25ce01042a97ea6a3fa6a954596a9e0?t=1729341487211",
                  "_blank"
                )
              }
            >
              Dextools
            </p>
            <p
              className="footer-link"
              onClick={() =>
                window.open("https://etherscan.io/token/0xd2ab7d66a25ce01042a97ea6a3fa6a954596a9e0", "_blank")
              }
            >
              Etherscan
            </p>

            <p
              className="footer-link"
              onClick={() =>
                window.open(
                  "https://app.uniswap.org/#/tokens/ethereum/0xd2ab7d66a25ce01042a97ea6a3fa6a954596a9e0",
                  "_blank"
                )
              }
            >
              Uniswap
            </p>
          </Col>
          <Col xs={12} md={2} className="mb-5 text-left">
            <h3 style={{ color: "#d3c7ef" }} className="footer-title">
              Socials
            </h3>
            {/* <p
              className="footer-link"
              onClick={() => window.open("", "_blank")}
            >
              Discord
            </p> */}
            <p
              className="footer-link"
              onClick={() => window.open("https://t.me/codynai", "_blank")}
            >
              Telegram
            </p>
            <p
              className="footer-link"
              onClick={() =>
                window.open("https://twitter.com/codyndev_ai", "_blank")
              }
            >
              Twitter
            </p>
          </Col>
          <Col xs={12} md={2} className="mb-5 text-left">
            <h3 style={{ color: "#d3c7ef" }} className="footer-title">
              Resources
            </h3>
            <p
              className="footer-link"
              onClick={() => window.open("https://docs.codyn.ai", "_blank")}
            >
              Docs
            </p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Home;
